import { Col, DatePicker, Input, Row } from "antd";
import { GlobalContext } from "contexts/GlobalContext";
import { useVolSurfaceControlTable } from "contexts/VolSurfaceControlTableContext";
import moment from "moment";
import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function SelectDate({
  setDateTime,
  dateTime,
  setSelectedFilters,
}) {
  const {
    globalState: { dark_Theme },
  } = useContext(GlobalContext);
  const location = useLocation();
  const { stickyStrikeData } = useVolSurfaceControlTable();

  const pathName = location.pathname;
  const isVolSurfacePage = pathName.includes("vol-surface");
  const hideDateFilterOnProdForVolSurface = !isVolSurfacePage;
  const handleRangePickerChange = (dateStr) => {
    setDateTime(dateStr);
  };

  const defaultDateTime = () => {
    let today = moment();
    // let defaultDate;
    // if (today.day() === 1) {
    //   // Checks if today is Monday
    //   defaultDate = today.subtract(3, "days"); // Goes back 3 days to Friday
    // } else {
    //   defaultDate = today.subtract(1, "days"); // Otherwise, just go back to yesterday
    // }
    // Set the time to 18:00:00
    today.hour(9);
    today.minute(0);
    today.second(0);

    return today.format("YYYY-MM-DD HH:mm:ss");
  };

  useEffect(() => {
    setSelectedFilters((prev) => ({
      ...prev,
      strike_date: moment(dateTime || defaultDateTime()).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateTime]);

  return (
    <>
      <Col style={{ width: "100%" }} span={24}>
        {!hideDateFilterOnProdForVolSurface && (
          <Row
            style={{ width: "100%" }}
            size={"middle"}
            className={`${dark_Theme && "date-picker-dark"}`}
          >
            <Col span={3}>
              <span style={{ whiteSpace: "nowrap" }}>
                Date/Time of Surface:
              </span>
            </Col>
            <Col>
              <Input
                value={moment(defaultDateTime()).format("YYYY-MM-DD HH:mm:ss")}
                disabled
                style={{
                  width: "197px",
                }}
              />
              {/* <DatePicker
                style={{
                  backgroundColor: "#606060",
                  border: "1px solid #d9d9d9 !important",
                  color: "rgb(205 205 205) !important",
                }}
                inputReadOnly
                disabled={true}
                onChange={handleRangePickerChange}
                className={
                  dark_Theme
                    ? "ant-input-number-dark-disabled"
                    : "ant-input-number-light-disabled"
                }
                popupClassName={dark_Theme && "date-picker-dropdown-dark"}
                value={
                   moment(defaultDateTime(), "YYYY-MM-DD HH:mm:ss")
                }
                showTime
              /> */}
            </Col>
            <Col offset={1} span={3}>
              <span style={{ whiteSpace: "nowrap" }}>
                Current EOD Surface Time:
              </span>
            </Col>
            <Col>
              <Input value={stickyStrikeData?.time} disabled />
            </Col>
          </Row>
        )}
      </Col>
    </>
  );
}

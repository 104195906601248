import {
  CloseCircleOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  ExportOutlined,
  FileTextOutlined,
  FontColorsOutlined,
  FormOutlined,
  ImportOutlined,
  InsertRowBelowOutlined,
} from "@ant-design/icons";
import ColorIcon from "components/Pricer/ColorIcon/ColorIcon";

export const RotateImportIcons = () => (
  <ImportOutlined
    style={{
      rotate: "-180deg",
      fontSize: "12px",
      marginRight: "7px",
    }}
  />
);

export const GRAPH_TABLE_FILTER_LABELS = {
  GRAPHS: "Graphs",
  TABLE: "Table",
};

export const Sticky_Delta_Strike_LABELS = {
  STICKY_Delta: "TFS Marks",
  STICKY_Strike: "Sticky Strike",
};

export const ACTION_BUTTONS_NAME = {
  COPY_SHORTHAND: {
    label: "Copy Shorthand",
    key: "copy_shorthand",
    icon: <CopyOutlined />,
  },
  COPY_SHORTHAND_NO_VOLS: {
    label: "Copy Shorthand - no vol",
    key: "copy_shorthand_no_vols",
    icon: <CopyOutlined />,
  },
  COPY_SHORTHAND_LEGS: {
    label: "Copy Shorthand - legs",
    key: "copy_shorthand_legs",
    icon: <CopyOutlined />,
  },
  PUSH_TO_TEAM_RUN: {
    label: "Push to team run",
    key: "push_to_team_run",
    icon: <RotateImportIcons />,
  },
  DUPLICATE: {
    label: "Duplicate",
    key: "option_duplicate",
    icon: <InsertRowBelowOutlined />,
  },
  DELETE: { label: "Delete", key: "option_delete", icon: <DeleteOutlined /> },
  VOID: { label: "Void", key: "void", icon: <DeleteOutlined /> },
  OTHER_INFO_AND_DETAILS: {
    label: "Other info and details",
    key: "other_info",
    icon: <FileTextOutlined />,
  },
  EDIT_OPTION_STRUCTURE: {
    label: "Edit option structure",
    key: "edit_option",
    icon: <FormOutlined />,
  },
  TRADE: { label: "Trade", key: "trade", icon: <EditOutlined /> },
  MENU: { label: "Menu", key: "", icon: "" },
  PUSH_TO_AUDIT_TRAIL: {
    label: "Push to audit trail",
    key: "push_to_audit_trail",
    icon: <FormOutlined />,
  },
  COLOR: {
    label: "Change row color",
    key: "change_row_color",
    icon: <FontColorsOutlined />,
  },
  GREEN: {
    label: "Green",
    key: "Green",
    icon: <ColorIcon color="--color-green-2" />,
  },
  YELLOW: {
    label: "Yellow",
    key: "Yellow",
    icon: <ColorIcon color="--color-yellow-2" />,
  },
  MAGNETA: {
    label: "Magenta",
    key: "Magenta",
    icon: <ColorIcon color="--color-magenta-1" />,
  },
  REMOVE_COLOR: {
    label: "Remove Color",
    key: "remove_color",
    icon: <CloseCircleOutlined />,
  },
  PULL_TO_PRICER: { label: "Pull To Pricer", key: "pull_to_pricer", icon: "" },
  UPDATE_BID: { label: "Update Bid", key: "update_bid", icon: "" },
  UPDATE_ASK: { label: "Update Ask", key: "update_ask", icon: "" },
  MENU_ITEMS: { label: "Menu Items", key: "menuItems", icon: "" },
  DETAILED_AUDIT_TRIAL: {
    label: "Detailed Audit Trail",
    key: "detailed_audit_trail",
    icon: "",
  },
  UN_HIGHLIGHT: { label: "Un-Highlight", key: "un_highlight", icon: "" },
  HIGHLIGHT: { label: "Highlight", key: "highlight", icon: "" },
  ARCHIVE: { label: "Archive", key: "archive", icon: "" },
  MAKE_ACTIVE: { label: "Make Active", key: "make_active", icon: "" },
  MAKE_IN_ACTIVE: { label: "Make Inactive", key: "make_in_active", icon: "" },
  UPDATE_OPTION: {
    label: "Update Option In Team Run",
    key: "updateOption",
    icon: "",
  },
  PUSH_OPTION: {
    label: "Push option to team run",
    key: "pushOption",
    icon: "",
  },
  PUSH_OPTION_TO_AUDIT_TRAIL: {
    label: "Push option to audit trail",
    key: "pushToAuditTrail",
    icon: "",
  },
  COPY_TRADE_DETAILS: {
    label: "Save & Copy Trade Details",
    key: "copyTradeDetails",
    icon: "",
  },
  ADDITIONAL_DATA: {
    label: "Additional Data",
    key: "additional_data",
    icon: "",
  },
  EDIT_TRADE_DETAILS: {
    label: "Edit Trade Details",
    key: "editTradeDetails",
    icon: "",
  },
  EXPORT_ROWS: {
    label: "Export Rows",
    key: "export_rows",
    icon: <ExportOutlined />,
  },
};

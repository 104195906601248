import CustomTradeInputForDelta from "components/Common/CustomTradeInputForDelta/CustomTradeInputForDelta";

function HedgeItem({
  hedge,
  placeholdersTitle,
  isSellActive,
  setIsRequestActive,
  handleHedgeQuantityChange,
  temp_summary_id,
  isDisabled,
  colId,
  trades,
  setIsDeltaRequestActive,
  oldTradeData,
  quantity_t,
}) {
  return (
    <CustomTradeInputForDelta
      isDelta={true}
      trades={trades}
      colId={colId}
      hedge={hedge}
      oldTradeData={oldTradeData}
      isDisabled={isDisabled}
      temp_summary_id={temp_summary_id}
      placeholdersTitle={placeholdersTitle}
      isSellActive={isSellActive}
      handleHedgeQuantityChange={handleHedgeQuantityChange}
      setIsRequestActive={setIsRequestActive}
      setIsDeltaRequestActive={setIsDeltaRequestActive}
      quantity_t={quantity_t}
    />
  );
}

export default HedgeItem;

/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Button, Row, Spin } from "antd";
import classNames from "classnames";
import { useLocation } from "react-router-dom";

import { SHORTCUT_FILTER } from "constants/volSurface";
import { storeInLocalStorage } from "helpers/Pricer/Table";
import React, { useContext, useEffect } from "react";
import { Sticky_Delta_Strike_LABELS } from "constants/generalActionButtons";
import { VolSurfaceContext } from "contexts/VolSurfaceContext";

export default function Shortcuts({
  dark_Theme,
  currentTab,
  selectedFilters,
  setSelectedFilters,
  handleShortcutChange,
}) {
  const location = useLocation();
  const pathName = location.pathname;
  const isSticky = currentTab === Sticky_Delta_Strike_LABELS.STICKY_Strike;
  const {
    state: { volSurfaceFilterShortcuts, isShortcutsLoading },
  } = useContext(VolSurfaceContext);



  function onClickHandle(item) {
    if(item.product_contract_style_id === selectedFilters["shortcutFilter"]) return
    setSelectedFilters((prev) => ({
      ...prev,
      product_id: item.product_contract_style_id,
      shortcutFilter: item.product_contract_style_id,
      comparison_surface_id: null,
      live_surface_id: null,
    }));
    storeInLocalStorage(
      SHORTCUT_FILTER,
      JSON.stringify(item.product_contract_style_id)
    );
    handleShortcutChange(item.product_contract_style_id);
  }

  useEffect(() => {
    if (pathName.includes("vol-surface-data")) {
      setSelectedFilters((prev) => ({
        ...prev,
        shortcutFilter:
          JSON.parse(localStorage.getItem(SHORTCUT_FILTER)) ||
          volSurfaceFilterShortcuts?.at(0)?.product_contract_style_id,
        product_id: volSurfaceFilterShortcuts
          ?.at(0)
          ?.product_contract_style_id.toString(),
      }));
    }
  }, [pathName, setSelectedFilters, volSurfaceFilterShortcuts]);

  useEffect(() => {
    if (isSticky && !pathName.includes("vol-surface-data")) {
      setSelectedFilters((prev) => ({
        ...prev,
        shortcutFilter:
          volSurfaceFilterShortcuts?.at(0)?.product_contract_style_id,
        product_id: volSurfaceFilterShortcuts
          ?.at(0)
          ?.product_contract_style_id.toString(),
      }));
    }
  }, [isSticky, pathName]);

  return (
    <Col span={24}>
      <Row className="bias-btn">
        <Col span={3}>Shortcuts:</Col>
        <Col>
          <Row gutter={12}>
            {isShortcutsLoading || !volSurfaceFilterShortcuts ? (
              <Spin size="medium" className="mx-4" />
            ) : (
              <>
                {volSurfaceFilterShortcuts?.map((item) => {
                  return (
                    <Col key={item.label}>
                      <Button
                        className={classNames({
                          "ant-btn-dark": dark_Theme,
                          "selected-button":
                            item?.product_contract_style_id ===
                            selectedFilters["shortcutFilter"],
                        })}
                        key={item.label}
                        onClick={() => onClickHandle(item)}
                      >
                        {item.label}
                      </Button>
                    </Col>
                  );
                })}
              </>
            )}
          </Row>
        </Col>
      </Row>
    </Col>
  );
}

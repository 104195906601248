// Components
import CheckBoxPricer from "components/Pricer/CheckBoxPricer";
import ActionButtons from "components/Pricer/ActionButtons";
import LockIcon from "components/Pricer/LockIcon";
import GreekDropDown from "components/Pricer/GreeksDropDown";
// Icons
// Helpers
import {
  valueFormatterFunc,
  valueParserFunc,
  comparatorSorter,
  disableSorterInSpecificColumns,
} from "helpers/Pricer/Table";
import { ACTION_BUTTONS_NAME } from "constants/generalActionButtons";
import OptionTypeDropdown from "components/Pricer/OptionTypeDropdown";
import TenorDropdown from "components/Pricer/TenorDropdown/TenorDropdown";
//# Constants
export const FIELD_NAMES = {
  ROW_TYPE: "row_type",
  FIRST_GREEK: "first_greek",
  SECOND_GREEK: "second_greek",
  OPTION_ROW_ID: "option_row_id",
  SUMMARY_ID: "summary_id",
  LEG_ID: "leg_id",
  LIVE: "live",
  PRODUCT: "product",
  CONTRACT: "contract",
  STYLE: "style",
  RISK_FREE: "risk_free",
  TENOR_LEG: "tenor.leg",
  TENOR: "tenor",
  UNDERLYING: "underlying",
  STRIKE: "strike",
  TYPE: "type",
  RATIO: "ratio",
  BID_QTY: "bid_quantity",
  BID_PERCENTAGE: "bid_percentage",
  BID: "bid",
  IS_LOCKED: "is_locked",
  ASK: "ask",
  COMPANY: "company_value",
  ASK_PERCENTAGE: "ask_percentage",
  ASK_QTY: "ask_quantity",
  ACTIONS: "actions",
  SUMMARY_REFERENCE_ID: "summary_reference_id",
  LEG_REFERENCE_ID: "leg_reference_id",
  COPY_SHORTHAND: "copy_shorthand",
  COPY_SHORTHAND_NO_VOLS: "copy_shorthand_without_vol",
  COPY_SHORTHAND_LEGS: "copy_shorthand_legs",
  ID: "id",
  VOLATILITY_UNIT: "volatility_unit",
  QUANTITY_UNIT: "premium_quantity_unit",
  PREMIUM_UNIT: "premium_unit",
  LEVEL_ONE_ROW_TYPE: "level_one_row_type",
  LEVEL_ZERO_ROW_TYPE: "level_zero_row_type",
  SUB_LEG: "subleg",
  LEG: "leg",
  SUMMARY: "summary",
  LEG_SUMMARY: "leg-summary",
  BID_DELTA: "bid_delta",
  COMPANY_VALUE: "company_value",
  DS_EST: "ds_est",
  TFS_SETTLES: "tfs_settles",
  SS_EST: "ss_est",
  COMPANY_PERCENTAGE: "company_percentage",
  ASK_DELTA: "ask_delta",
  CALCULATION_DATE: "calculation_date",
  SUMMARY_AGG: "summary_agg",
  LEG_AGG: "leg_agg",
  LEVEL: "level",
  LEGS: "legs",
  FIELD: "field",
};

export const GREEKS_COLUMNS_FIELDS_NAME = {
  //? BID
  BID_DELTA: "bid_delta",
  BID_THETA: "bid_theta",
  BID_GAMMA: "bid_gamma",
  BID_VEGA: "bid_vega",
  BID_RHO: "bid_rho",
  BID_TOTAL_PREMIUM: "bid_total_premium",
  BID_TOTAL_QTY: "bid_total_quantity",
  BID_GAMMA_QTY: "bid_gamma_quantity",
  BID_DELTA_QTY: "bid_delta_quantity",
  BID_THETA_CURRENCY: "bid_theta_currency",
  BID_VEGA_CURRENCY: "bid_vega_currency",
  BID_RHO_CURRENCY: "bid_rho_currency",
  BID_BREAKEVEN: "bid_breakeven",
  //? ASK
  ASK_DELTA: "ask_delta",
  ASK_THETA: "ask_theta",
  ASK_GAMMA: "ask_gamma",
  ASK_VEGA: "ask_vega",
  ASK_RHO: "ask_rho",
  ASK_TOTAL_PREMIUM: "ask_total_premium",
  ASK_TOTAL_QTY: "ask_total_quantity",
  ASK_GAMMA_QTY: "ask_gamma_quantity",
  ASK_DELTA_QTY: "ask_delta_quantity",
  ASK_THETA_CURRENCY: "ask_theta_currency",
  ASK_VEGA_CURRENCY: "ask_vega_currency",
  ASK_RHO_CURRENCY: "ask_rho_currency",
  ASK_BREAKEVEN: "ask_breakeven",
};

export const UNIT_COLUMNS_FIELDS_NAME = {
  //? BID
  PREMIUM_UNIT: "premium_unit",
  UNDERLYING_UNIT: "underlying_unit",
  STRIKE_UNIT: "strike_unit",
  RISK_FREE_UNIT: "risk_free_unit",
  VOLATILITY_UNIT: "premium_percentage_unit",
  THETA_UNIT: "premium_theta_unit",
  DELTA_UNIT: "premium_delta_unit",
  GAMMA_UNIT: "premium_gamma_unit",

  PREMIUM_QUANTITY_UNIT: "premium_quantity_unit",
  PREMIUM_VEGA_CURRENCY_UNIT: "premium_vega_currency_unit",
  PREMIUM_RHO_CURRENCY_UNIT: "premium_rho_currency_unit",
  PREMIUM_TOTAL_PREMIUM_UNIT: "premium_total_premium_unit",

  PREMIUM_BREAKEVEN_UNIT: "premium_breakeven_unit",
  PREMIUM_VEGA_UNIT: "premium_vega_unit",
  PREMIUM_RHO_UNIT: "premium_rho_unit",
  PREMIUM_THETA_CURRENCY_UNIT: "premium_theta_currency_unit",

  PREMIUM_GAMMA_QUANTITY_UNIT: "premium_gamma_quantity_unit",
  PREMIUM_DELTA_QUANTITY_UNIT: "premium_delta_quantity_unit",
  PREMIUM_TOTAL_QUANTITY_UNIT: "premium_total_quantity_unit",
};

export const NON_AGGREGABLE_COLUMNS = [
  FIELD_NAMES.LEG_ID,
  FIELD_NAMES.SUMMARY_ID,
  FIELD_NAMES.LEG_REFERENCE_ID,
  FIELD_NAMES.SUMMARY_REFERENCE_ID,
  ...Object.values(UNIT_COLUMNS_FIELDS_NAME),
  FIELD_NAMES.RATIO,
];

const COMMON_EDITABLE_FIELDS_BASED_ON_LEVEL = [
  FIELD_NAMES.BID,
  FIELD_NAMES.BID_PERCENTAGE,
  FIELD_NAMES.ASK,
  FIELD_NAMES.ASK_PERCENTAGE,
];

export const EDITABLE_FIELDS_BASED_ON_LEVEL = {
  [FIELD_NAMES.SUMMARY]: [
    ...COMMON_EDITABLE_FIELDS_BASED_ON_LEVEL,
    FIELD_NAMES.BID_QTY,
    FIELD_NAMES.ASK_QTY,
    FIELD_NAMES.RISK_FREE,
  ],
  [FIELD_NAMES.LEG]: [
    ...COMMON_EDITABLE_FIELDS_BASED_ON_LEVEL,
    FIELD_NAMES.STRIKE,
    FIELD_NAMES.UNDERLYING,
    FIELD_NAMES.RATIO,
  ],
  [FIELD_NAMES.SUB_LEG]: [
    ...COMMON_EDITABLE_FIELDS_BASED_ON_LEVEL,
    FIELD_NAMES.UNDERLYING,
  ],
};

export const HIDE_VALUES_BASED_ON_LEVEL = {
  [FIELD_NAMES.SUMMARY]: [
    GREEKS_COLUMNS_FIELDS_NAME.ASK_TOTAL_QTY,
    GREEKS_COLUMNS_FIELDS_NAME.BID_TOTAL_QTY,
    GREEKS_COLUMNS_FIELDS_NAME.ASK_BREAKEVEN,
    GREEKS_COLUMNS_FIELDS_NAME.BID_BREAKEVEN,
    GREEKS_COLUMNS_FIELDS_NAME.BID_RHO,
    GREEKS_COLUMNS_FIELDS_NAME.ASK_RHO,
    GREEKS_COLUMNS_FIELDS_NAME.BID_VEGA,
    GREEKS_COLUMNS_FIELDS_NAME.ASK_VEGA,
    GREEKS_COLUMNS_FIELDS_NAME.BID_THETA,
    GREEKS_COLUMNS_FIELDS_NAME.ASK_THETA,
  ],
  [FIELD_NAMES.LEG]: [
    FIELD_NAMES.LIVE,
    FIELD_NAMES.IS_LOCKED,
    FIELD_NAMES.ACTIONS,
  ],
  [FIELD_NAMES.SUB_LEG]: [
    GREEKS_COLUMNS_FIELDS_NAME.BID_TOTAL_PREMIUM,
    GREEKS_COLUMNS_FIELDS_NAME.ASK_TOTAL_PREMIUM,

    GREEKS_COLUMNS_FIELDS_NAME.ASK_TOTAL_QTY,
    GREEKS_COLUMNS_FIELDS_NAME.BID_TOTAL_QTY,

    GREEKS_COLUMNS_FIELDS_NAME.ASK_RHO_CURRENCY,
    GREEKS_COLUMNS_FIELDS_NAME.BID_RHO_CURRENCY,

    GREEKS_COLUMNS_FIELDS_NAME.ASK_VEGA_CURRENCY,
    GREEKS_COLUMNS_FIELDS_NAME.BID_VEGA_CURRENCY,

    GREEKS_COLUMNS_FIELDS_NAME.ASK_BREAKEVEN,
    GREEKS_COLUMNS_FIELDS_NAME.BID_BREAKEVEN,

    GREEKS_COLUMNS_FIELDS_NAME.ASK_DELTA_QTY,
    GREEKS_COLUMNS_FIELDS_NAME.BID_DELTA_QTY,

    GREEKS_COLUMNS_FIELDS_NAME.ASK_GAMMA_QTY,
    GREEKS_COLUMNS_FIELDS_NAME.BID_GAMMA_QTY,

    GREEKS_COLUMNS_FIELDS_NAME.BID_THETA_CURRENCY,
    GREEKS_COLUMNS_FIELDS_NAME.ASK_THETA_CURRENCY,

    FIELD_NAMES.BID_QTY,
    FIELD_NAMES.ASK_QTY,

    FIELD_NAMES.PRODUCT,
    FIELD_NAMES.CONTRACT,
    FIELD_NAMES.STYLE,
    FIELD_NAMES.RISK_FREE,
    FIELD_NAMES.LIVE,
    FIELD_NAMES.TYPE,
    FIELD_NAMES.RATIO,

    FIELD_NAMES.IS_LOCKED,

    FIELD_NAMES.ACTIONS,
    FIELD_NAMES.STRIKE,
  ],
};

export const HIDE_VALUES_IN_SUBLEGS = [FIELD_NAMES.PRODUCT];
export const UNEDITABLE_FIELDS_BASED_ON_MORE_THAN_TWO_LEGS = [
  FIELD_NAMES.BID_PERCENTAGE,
  FIELD_NAMES.ASK_PERCENTAGE,
  FIELD_NAMES.TENOR,
  FIELD_NAMES.UNDERLYING,
];

export const HIDE_VALUES_BASED_FOR_MORE_THAN_TWO_LEGS = [
  FIELD_NAMES.TENOR,
  FIELD_NAMES.UNDERLYING,
  FIELD_NAMES.BID_PERCENTAGE,
  FIELD_NAMES.ASK_PERCENTAGE,
];

export const PRICER_COLUMNS = (initial) =>
  [
    {
      headerName: "",
      field: "",
      maxWidth: 80,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: (params) => params.node.level === 0 && true,
    },
    {
      hide: true,
      field: FIELD_NAMES.ID,
    },
    {
      headerName: "Commodity",
      filter: true,
      field: FIELD_NAMES.PRODUCT,
    },
    {
      headerName: "Contract",
      field: FIELD_NAMES.CONTRACT,
    },
    {
      headerName: "Style",
      field: FIELD_NAMES.STYLE,
    },
    {
      headerName: "Rf %",
      field: FIELD_NAMES.RISK_FREE,
    },
    {
      headerName: "Tenor",
      tooltipField: "expiry_date",
      tooltipComponent: "customTooltip",
      minWidth: 100,
      cellRenderer: TenorDropdown,
      // without this line it won't work on grouped rows
      tooltipValueGetter: (params) => {
        return !params.node.group
          ? params.node.data.expiry_date
          : params.node.aggData.row_type === 1
          ? params.node.aggData.summary_agg?.expiry_date
          : params.node.aggData?.expiry_date;
      },
      field: FIELD_NAMES.TENOR,
    },
    {
      headerName: "U/L",
      field: FIELD_NAMES.UNDERLYING,
    },
    {
      headerName: "Live",
      field: FIELD_NAMES.LIVE,
      cellRendererFramework: CheckBoxPricer,
    },
    {
      headerName: "Strike",
      field: FIELD_NAMES.STRIKE,
    },

    {
      headerName: "Type",
      field: FIELD_NAMES.TYPE,
      showRowGroup: true,
      minWidth: 175,
      cellRenderer: "agGroupCellRenderer",

      cellRendererParams: {
        suppressCount: true,
        innerRenderer: OptionTypeDropdown,
      },
    },

    {
      headerName: "Ratio",
      field: FIELD_NAMES.RATIO,
      //sign remains if the ratio is negative
      cellEditorParams: function (params) {
        var ratio = params.value;
        if (ratio > 0) {
          return {
            value: ratio,
          };
        } else {
          return {
            value: Math.abs(ratio),
          };
        }
      },
    },
    {
      headerName: "Bid Qty",
      field: FIELD_NAMES.BID_QTY,
    },
    {
      headerName: "Bid %",
      field: FIELD_NAMES.BID_PERCENTAGE,
    },
    {
      headerName: "Bid",
      field: FIELD_NAMES.BID,
    },
    {
      headerName: "",
      field: FIELD_NAMES.IS_LOCKED,
      cellRendererFramework: LockIcon,
      maxWidth: 50,
    },
    {
      headerName: "Ask",
      field: FIELD_NAMES.ASK,
    },
    {
      headerName: "Ask %",
      field: FIELD_NAMES.ASK_PERCENTAGE,
    },
    {
      headerName: "Ask Qty",
      field: FIELD_NAMES.ASK_QTY,
    },
    {
      headerName: "Actions",
      field: FIELD_NAMES.ACTIONS,
      minWidth: 85,
      initialWidth: 85,
      cellRenderer: ActionButtons,
    },
    {
      field: FIELD_NAMES.SUMMARY_REFERENCE_ID,
      rowGroup: true,
      hide: true,
      cellRendererParams: {
        suppressCount: true,
      },
    },
    {
      field: FIELD_NAMES.LEG_REFERENCE_ID,
      rowGroup: true,
      hide: true,
      cellRendererParams: {
        suppressCount: true,
      },
    },
    {
      hide: true,
      field: FIELD_NAMES.COPY_SHORTHAND,
    },
    {
      hide: true,
      field: FIELD_NAMES.COPY_SHORTHAND_NO_VOLS,
    },
    {
      field: FIELD_NAMES.ID,
      filter: true,
      hide: true,
    },
    {
      field: FIELD_NAMES.SUMMARY_ID,
      hide: true,
      rowDrag: true,
    },
    {
      field: FIELD_NAMES.LEVEL_ONE_ROW_TYPE,
      hide: true,
      rowDrag: true,
    },
    {
      field: FIELD_NAMES.LEVEL_ZERO_ROW_TYPE,
      hide: true,
      rowDrag: true,
    },
    {
      field: FIELD_NAMES.LEG_ID,
      hide: true,
    },
    {
      field: FIELD_NAMES.DS_EST,
      headerName: "TFS Marks",
      minWidth: 100,
    },
    // {
    //   field: FIELD_NAMES.TFS_SETTLES,
    //   headerName: "Comp Est",
    //   minWidth: 100,
    // },
    {
      field: FIELD_NAMES.SS_EST,
      headerName: "SS Est",
      minWidth: 100,
    },
    {
      minWidth: 160,
      colId: initial?.bid || FIELD_NAMES.BID_DELTA,
      field: initial?.bid || FIELD_NAMES.BID_DELTA,
      cellClass: "greek-column-cell",
      headerComponent: GreekDropDown,
    },
    {
      minWidth: 160,
      colId: initial?.ask || FIELD_NAMES.ASK_DELTA,
      field: initial?.ask || FIELD_NAMES.ASK_DELTA,
      cellClass: "greek-column-cell",
      headerComponent: GreekDropDown,
    },
    {
      field: FIELD_NAMES.SUMMARY_AGG,
      hide: true,
      colId: FIELD_NAMES.SUMMARY_AGG,
    },
    {
      field: FIELD_NAMES.LEG_AGG,
      hide: true,
      colId: FIELD_NAMES.LEG_AGG,
    },
    {
      field: "rowColor",
      hide: true,
      colId: "rowColor",
      editable: true,
    },
    ...Object.values(UNIT_COLUMNS_FIELDS_NAME).map((value) => {
      return {
        field: value,
        hide: true,
      };
    }),
  ].map((item) => {
    return {
      ...item,
      menuTabs: [],
      width: 70,
      resizable: true,
      sortable: disableSorterInSpecificColumns(item),
      valueFormatter: valueFormatterFunc,
      valueParser: valueParserFunc,
      comparator: comparatorSorter(item),
    };
  });

export const ACTION_PRICER_BUTTONS_MENU_ITEMS = [
  {
    key: ACTION_BUTTONS_NAME.COPY_SHORTHAND.key,
    label: ACTION_BUTTONS_NAME.COPY_SHORTHAND.label,
    icon: ACTION_BUTTONS_NAME.COPY_SHORTHAND.icon,
  },
  {
    key: ACTION_BUTTONS_NAME.COPY_SHORTHAND_NO_VOLS.key,
    label: ACTION_BUTTONS_NAME.COPY_SHORTHAND_NO_VOLS.label,
    icon: ACTION_BUTTONS_NAME.COPY_SHORTHAND_NO_VOLS.icon,
  },
  {
    key: ACTION_BUTTONS_NAME.COPY_SHORTHAND_LEGS.key,
    label: ACTION_BUTTONS_NAME.COPY_SHORTHAND_LEGS.label,
    icon: ACTION_BUTTONS_NAME.COPY_SHORTHAND_LEGS.icon,
  },
  {
    key: ACTION_BUTTONS_NAME.PUSH_TO_TEAM_RUN.key,
    label: ACTION_BUTTONS_NAME.PUSH_TO_TEAM_RUN.label,
    icon: ACTION_BUTTONS_NAME.PUSH_TO_TEAM_RUN.icon,
  },
  {
    key: ACTION_BUTTONS_NAME.DUPLICATE.key,
    label: ACTION_BUTTONS_NAME.DUPLICATE.label,
    icon: ACTION_BUTTONS_NAME.DUPLICATE.icon,
  },
  {
    key: ACTION_BUTTONS_NAME.DELETE.key,
    label: ACTION_BUTTONS_NAME.DELETE.label,
    icon: ACTION_BUTTONS_NAME.DELETE.icon,
  },
  {
    key: ACTION_BUTTONS_NAME.OTHER_INFO_AND_DETAILS.key,
    label: ACTION_BUTTONS_NAME.OTHER_INFO_AND_DETAILS.label,
    icon: ACTION_BUTTONS_NAME.OTHER_INFO_AND_DETAILS.icon,
  },
  {
    key: ACTION_BUTTONS_NAME.PUSH_TO_AUDIT_TRAIL.key,
    label: ACTION_BUTTONS_NAME.PUSH_TO_AUDIT_TRAIL.label,
    icon: ACTION_BUTTONS_NAME.PUSH_TO_AUDIT_TRAIL.icon,
  },
  {
    key: ACTION_BUTTONS_NAME.COLOR.key,
    label: ACTION_BUTTONS_NAME.COLOR.label,
    icon: ACTION_BUTTONS_NAME.COLOR.icon,
    children: [
      {
        key: ACTION_BUTTONS_NAME.REMOVE_COLOR.key,
        label: ACTION_BUTTONS_NAME.REMOVE_COLOR.label,
        icon: ACTION_BUTTONS_NAME.REMOVE_COLOR.icon,
      },
      {
        key: ACTION_BUTTONS_NAME.GREEN.key,
        label: ACTION_BUTTONS_NAME.GREEN.label,
        icon: ACTION_BUTTONS_NAME.GREEN.icon,
      },
      {
        key: ACTION_BUTTONS_NAME.YELLOW.key,
        label: ACTION_BUTTONS_NAME.YELLOW.label,
        icon: ACTION_BUTTONS_NAME.YELLOW.icon,
      },
      {
        key: ACTION_BUTTONS_NAME.MAGNETA.key,
        label: ACTION_BUTTONS_NAME.MAGNETA.label,
        icon: ACTION_BUTTONS_NAME.MAGNETA.icon,
      },
    ],
  },
];

export const BULK_ACTIONS = ACTION_PRICER_BUTTONS_MENU_ITEMS.filter(
  (item) =>
    item.key === ACTION_BUTTONS_NAME.COPY_SHORTHAND.key ||
    item.key === ACTION_BUTTONS_NAME.COPY_SHORTHAND_NO_VOLS.key ||
    item.key === ACTION_BUTTONS_NAME.DUPLICATE.key ||
    item.key === ACTION_BUTTONS_NAME.DELETE.key ||
    item.key === ACTION_BUTTONS_NAME.COLOR.key
);

export const PARAMS_FRONTEND_ROUNDING_AND_FORMATTING = {
  company_value: {
    roundedTo: 3,
    formattedTo: 3,
    showMinDecimals: true,
  },
  company_percentage: {
    roundedTo: 2,
    formattedTo: 2,
    showMinDecimals: true,
  },
  strike: {
    roundedTo: 1,
    formattedTo: 1,
    showMinDecimals: true,
  },
  underlying: {
    roundedTo: 2,
    formattedTo: 2,
  },
  ratio: {
    roundedTo: 2,
    formattedTo: 2,
    showMinDecimals: true,
  },
  risk_free: {
    roundedTo: 1,
    formattedTo: 1,
    showMinDecimals: true,
  },
  premium: {
    roundedTo: 3,
    formattedTo: 3,
  },
  premium_percentage: {
    roundedTo: 2,
    formattedTo: 2,
  },
  premium_quantity: {
    roundedTo: 0,
    formattedTo: 0,
  },
  premium_delta: {
    roundedTo: 14,
    formattedTo: 2,
  },
  premium_gamma: {
    roundedTo: 14,
    formattedTo: 2,
  },
  premium_theta: {
    roundedTo: 14,
    formattedTo: 2,
  },
  premium_vega: {
    roundedTo: 14,
    formattedTo: 2,
  },
  premium_rho: {
    roundedTo: 14,
    formattedTo: 2,
  },
  premium_total_premium: {
    roundedTo: 2,
    formattedTo: 2,
  },
  premium_total_quantity: {
    roundedTo: 0,
    formattedTo: 0,
  },
  premium_delta_quantity: {
    roundedTo: 2,
    formattedTo: 2,
  },
  premium_gamma_quantity: {
    roundedTo: 2,
    formattedTo: 2,
  },
  premium_theta_currency: {
    roundedTo: 2,
    formattedTo: 2,
  },
  premium_vega_currency: {
    roundedTo: 2,
    formattedTo: 2,
  },
  premium_rho_currency: {
    roundedTo: 2,
    formattedTo: 2,
  },
  premium_breakeven: {
    roundedTo: 2,
    formattedTo: 2,
  },
};

export const ALWAYS_POSITIVE_PARAMS = [
  FIELD_NAMES.STRIKE,
  FIELD_NAMES.BID_QTY,
  FIELD_NAMES.ASK_QTY,
  FIELD_NAMES.STRIKE,
  FIELD_NAMES.UNDERLYING,
  FIELD_NAMES.RATIO,
];

export const ALWAYS_INTEGER_PARAMS = [];

export const ALWAYS_POSITIVE_LEG_AND_SUB_LEG_PARAMS = [
  FIELD_NAMES.BID,
  FIELD_NAMES.BID_PERCENTAGE,
  FIELD_NAMES.ASK,
  FIELD_NAMES.ASK_PERCENTAGE,
];

export const COLUMN_CELL_TO_HAVE_RIGHT_BORDER = [
  FIELD_NAMES.TENOR_LEG,
  FIELD_NAMES.LIVE,
  FIELD_NAMES.RATIO,
  FIELD_NAMES.ASK_QTY,
  FIELD_NAMES.ACTIONS,
  FIELD_NAMES.TENOR,
];

export const SUMMARY_LEVEL_REVERSE_AGGREGATE_PARAMS = [
  FIELD_NAMES.BID_QTY,
  FIELD_NAMES.ASK_QTY,
  FIELD_NAMES.UNDERLYING,
  FIELD_NAMES.RISK_FREE,
  FIELD_NAMES.BID_PERCENTAGE,
  FIELD_NAMES.ASK_PERCENTAGE,
];

export const LEG_LEVEL_REVERSE_AGGREGATE_PARAMS = [
  FIELD_NAMES.BID,
  FIELD_NAMES.ASK,
  FIELD_NAMES.BID_PERCENTAGE,
  FIELD_NAMES.ASK_PERCENTAGE,
  FIELD_NAMES.UNDERLYING,
  FIELD_NAMES.STRIKE,
  FIELD_NAMES.RATIO,
];

export const MAX_TWO_CHILDREN_FOR_REVERSE_AGGREGATION = [
  FIELD_NAMES.ASK_PERCENTAGE,
  FIELD_NAMES.BID_PERCENTAGE,
  FIELD_NAMES.ASK,
  FIELD_NAMES.BID,
];

export const QUANTITY_COLUMNS = [FIELD_NAMES.ASK_QTY, FIELD_NAMES.BID_QTY];
export const VOLATILITY_COLUMNS = [
  FIELD_NAMES.ASK_PERCENTAGE,
  FIELD_NAMES.BID_PERCENTAGE,
];

export const RUST_COLUMNS = [
  PRICER_COLUMNS.RATIO,
  PRICER_COLUMNS.UNDERLYING,
  PRICER_COLUMNS.STRIKE,
  PRICER_COLUMNS.TENOR_LEG,
];

export const AGGREGATE_SUM_PRODUCT_BY_RATIO_COLUMNS = [
  PRICER_COLUMNS.BID,
  PRICER_COLUMNS.ASK,
];

export const AGGREGATE_LEG_TO_SUMMARY_SUM_COLUMNS = [
  GREEKS_COLUMNS_FIELDS_NAME.BID_DELTA_QTY,
  GREEKS_COLUMNS_FIELDS_NAME.ASK_DELTA_QTY,
  GREEKS_COLUMNS_FIELDS_NAME.BID_GAMMA_QTY,
  GREEKS_COLUMNS_FIELDS_NAME.ASK_GAMMA_QTY,
  GREEKS_COLUMNS_FIELDS_NAME.BID_VEGA_CURRENCY,
  GREEKS_COLUMNS_FIELDS_NAME.ASK_VEGA_CURRENCY,
  GREEKS_COLUMNS_FIELDS_NAME.BID_RHO_CURRENCY,
  GREEKS_COLUMNS_FIELDS_NAME.ASK_RHO_CURRENCY,
  GREEKS_COLUMNS_FIELDS_NAME.BID_TOTAL_PREMIUM,
  GREEKS_COLUMNS_FIELDS_NAME.ASK_TOTAL_PREMIUM,
];

export const DEFAULT_SORT = [
  {
    colId: FIELD_NAMES.PRODUCT,
    sort: "asc",
    sortIndex: 1,
  },
  {
    colId: FIELD_NAMES.CONTRACT,
    sort: "asc",
    sortIndex: 2,
  },
  {
    colId: FIELD_NAMES.TENOR,
    sort: "asc",
    sortIndex: 3,
  },
  {
    colId: FIELD_NAMES.TYPE,
    sort: "asc",
    sortIndex: 4,
  },
  {
    colId: FIELD_NAMES.STRIKE,
    sort: "asc",
    sortIndex: 5,
  },
];

export const TEAM_RUN_DEFAULT_SORT = [
  ...DEFAULT_SORT,
  {
    colId: "is_active",
    sort: "desc",
    sortIndex: 0,
  },
];

export const DEFAULT_SORT_NULL = [
  {
    colId: FIELD_NAMES.PRODUCT,
    sort: "asc",
  },
  {
    colId: FIELD_NAMES.CONTRACT,
    sort: "asc",
  },
  {
    colId: FIELD_NAMES.STYLE,
    sort: "asc",
  },
  {
    colId: FIELD_NAMES.TENOR,
    sort: "asc",
  },
  {
    colId: FIELD_NAMES.STRIKE,
    sort: "asc",
  },
];

export const GREEK_COLUMNS_WITH_LABELS = {
  company: [
    {
      key: "company_value",
      label: "Est Prem",
    },
    {
      key: "company_percentage",
      label: "Est %",
    },
  ],
  bid: [
    {
      key: "bid_delta",
      label: "Bid Delta",
    },
    {
      key: "bid_delta_quantity",
      label: "Bid Delta Quantity",
    },
    {
      key: "bid_gamma",
      label: "Bid Gamma",
    },
    {
      key: "bid_gamma_quantity",
      label: "Bid Gamma Quantity",
    },
    {
      key: "bid_theta",
      label: "Bid Theta",
    },
    {
      key: "bid_theta_currency",
      label: "Bid Theta Currency",
    },
    {
      key: "bid_rho",
      label: "Bid Rho",
    },
    {
      key: "bid_rho_currency",
      label: "Bid Rho Currency",
    },
    {
      key: "bid_vega",
      label: "Bid Vega",
    },
    {
      key: "bid_vega_currency",
      label: "Bid Vega Currency",
    },
    {
      key: "bid_breakeven",
      label: "Bid Breakeven",
    },
    {
      key: "bid_total_premium",
      label: "Bid Total Premium",
    },
    {
      key: "bid_total_quantity",
      label: "Bid Total Quantity",
    },
  ],
  ask: [
    {
      key: "ask_delta",
      label: "Ask Delta",
    },
    {
      key: "ask_delta_quantity",
      label: "Ask Delta Quantity",
    },
    {
      key: "ask_gamma",
      label: "Ask Gamma",
    },
    {
      key: "ask_gamma_quantity",
      label: "Ask Gamma Quantity",
    },
    {
      key: "ask_theta",
      label: "Ask Theta",
    },
    {
      key: "ask_theta_currency",
      label: "Ask Theta Currency",
    },
    {
      key: "ask_rho",
      label: "Ask Rho",
    },
    {
      key: "ask_rho_currency",
      label: "Ask Rho Currency",
    },
    {
      key: "ask_vega",
      label: "Ask Vega",
    },
    {
      key: "ask_vega_currency",
      label: "Ask Vega Currency",
    },
    {
      key: "ask_breakeven",
      label: "Ask Breakeven",
    },
    {
      key: "ask_total_premium",
      label: "Ask Total Premium",
    },
    {
      key: "ask_total_quantity",
      label: "Ask Total Quantity",
    },
  ],
};

export const PRICER_NODES_COLORS = "pricer_nodeColors";
export const RowColor = "rowColor";
export const GreenColor = "--color-green-2";
export const YellowColor = "--color-yellow-2";
export const MagentaColor = "--color-magenta-1";

export const PRICER_KEYS_NOT_INCLUDED = [
  "premium_unit",
  "underlying_unit",
  "strike_unit",
  "risk_free_unit",
  "premium_percentage_unit",
  "premium_theta_unit",
  "premium_delta_unit",
  "premium_gamma_unit",
  "premium_quantity_unit",
  "premium_vega_currency_unit",
  "premium_rho_currency_unit",
  "premium_total_premium_unit",
  "premium_breakeven_unit",
  "premium_vega_unit",
  "premium_rho_unit",
  "premium_theta_currency_unit",
  "premium_gamma_quantity_unit",
  "premium_delta_quantity_unit",
  "premium_total_quantity_unit",
  "copy_shorthand_legs",
  "summary_agg",
  "expiry_date",
  "children_count",
];

export const TENOR_EXPIRY = "Tenor Expiry";
export const OTHER_INFORMATION = "Other Information";
export const EDIT_OTHER_DETAILS = "Edit Other Details";
export const OPTION = "Option";

export const EDIT_OTHER_DETAILS_LABELS = {
  CURRENT_AVERAGE: {
    label: "Current Average",
    isDisabled: true,
    key: "average",
  },
  NEW_AVERAGE: { label: "New Average", isDisabled: false, key: "new_average" },
  CURRENT_COST_OF_CARRY: {
    label: "Current Cost Of Carry",
    isDisabled: true,
    key: "cost_of_carry",
  },
  NEW_COST_OF_CARRY: {
    label: "New Cost Of Carry",
    isDisabled: false,
    key: "new_cost_of_carry",
  },
};

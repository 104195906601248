import { useCallback, useState } from "react";
import {
  getVolSurfaceStickyStrikes,
  updateStickyStrikeCell,
} from "api/volSurfaceApi";
import { useVolSurfaceControlTable } from "contexts/VolSurfaceControlTableContext";
import { revertCellValue, toast } from "helpers/generalHelper";
import { updateVolSurfaceData } from "helpers/volSurfaceHelper";

function useUpdateStrikeTable({ toNextCell, gridRef }) {
  const [premiums, setPremiums] = useState([]);
  const [showUpdateModal, setShowUpdateModal] = useState({
    show: false,
    data: null,
  });

  const {
    selectedFilters,
    setStickyStrikeData,
    stickyStrikeData,
    isCellEditing,
    setIsCellEditing,
    setIsCellLoading,
  } = useVolSurfaceControlTable();

  const onCellEditingStopped = useCallback(
    async (event, keep_strikes) => {
      const oldValue =
        event.oldValue === null ? event.oldValue : +event.oldValue;
      const newValue =
        event.oldValue === null ? event.newValue : +event.newValue;

      if (oldValue !== newValue && newValue !== null && !isNaN(newValue)) {
        let prepareBody = {
          vol_smile_id: event.data?.vol_smile_id,
          key: event.data?.key.toString(),
          risk_free: selectedFilters.risk_free || 0,
          calculation_date: selectedFilters?.strike_date.split(" ")[0],
        };

        const field = event.colDef.field;
        if (field === "underlying" || field === "strike") {
          setIsCellEditing(true);
        }
        if (field === "underlying") {
          prepareBody.underlying = +event.newValue;
          prepareBody.keep_strikes = keep_strikes;
        } else if (field === "premium") {
          setPremiums((prevPremiums) => {
            return [
              ...prevPremiums,
              {
                key: prepareBody.key,
                value: +event.newValue,
                event: event,
              },
            ];
          });
          prepareBody.premiums = premiums;
        } else if (field === "strike") {
          prepareBody.new_strike = +event.newValue;
        }

        if (field === "underlying" || field === "strike") {
          setIsCellLoading(true);
          await updateStickyStrikeCell(prepareBody)
            .then((res) => {
              if (res.status !== 200) {
                revertCellValue(event);
                setIsCellEditing(false);
              } else {
                if (field === "underlying") {
                  getTableData();
                } else {
                  setIsCellEditing(false);
                  goToNextCell(event);
                  const updatedData = updateVolSurfaceData(
                    [res.data.data],
                    stickyStrikeData?.table
                  );
                  setStickyStrikeData({
                    ...stickyStrikeData,
                    table: updatedData,
                  });
                  refreshTable();
                }
              }
            })
            .catch((e) => {
              setIsCellEditing(false);
              revertCellValue(event);
              console.log("Error while setting data");
            })
            .finally(() => {
              setIsCellLoading(false);
            });
        }
      } else {
        revertCellValue(event);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stickyStrikeData, toNextCell]
  );

  function goToNextCell(event) {
    if (toNextCell) {
      gridRef.current.api.startEditingCell({
        rowIndex: event.rowIndex + 1,
        colKey: event.column.colId,
      });
    }
  }

  function onUpdatePremiums(event, lastPremium) {
    var prepareBody = {
      vol_smile_id: event
        ? event.data?.vol_smile_id
        : premiums[0]?.event?.data?.vol_smile_id,
      key: event ? event.data?.key.toString() : premiums[0]?.key,
      risk_free: selectedFilters.risk_free || 0,
      calculation_date: selectedFilters?.strike_date.split(" ")[0],
    };

    const newPremiums = premiums.map((prem) => {
      return {
        key: prem.key,
        value: prem.value,
      };
    });

    if (lastPremium) {
      prepareBody.premiums = newPremiums;
      prepareBody.premiums.push({
        key: lastPremium.key,
        value: lastPremium.value,
      });
    } else {
      prepareBody.premiums = newPremiums;
    }

    gridRef.current.api.deselectAll();
    gridRef.current.api.clearFocusedCell();
    setIsCellLoading(true);
    setIsCellEditing(true);
    updateStickyStrikeCell(prepareBody)
      .then((res) => {
        const data = res?.data?.data;
        const highlights = data?.highlightedKeys || []; 

        let rowsWithNewSummaryId = [];
        highlights.length &&
          highlights?.forEach((item) => {
            gridRef.current?.api?.forEachNode((node) => {
              const nodeData = node.data;
              if (!nodeData) return;
              if (nodeData.id === item.id) rowsWithNewSummaryId.push(node);
            });
          });


        rowsWithNewSummaryId.length &&
          rowsWithNewSummaryId?.forEach((node) => {
            const nodeData = node.data;
            nodeData.isHighlighted = true;
          });


        // setTimeout(() => {
        //   gridRef.current?.api.flashCells({ rowNodes: rowsWithNewSummaryId });
        // }, 500);

        setIsCellEditing(false);

        const updatedData = updateVolSurfaceData(
          [...data?.rows],
          stickyStrikeData?.table
        );
        setStickyStrikeData({
          ...stickyStrikeData,
          table: updatedData,
        });
        if (data?.show_highlighted_message) {
          toast(data?.highlightMessage, "warning");
        }
        refreshTable();
      })
      .catch((e) => {
        getTableData();
        console.log("Error while setting data");
      })
      .finally(() => {
        setPremiums([]);
        setIsCellLoading(false);
      });
  }

  function getTableData() {
    getVolSurfaceStickyStrikes({
      product_contract_style_id: selectedFilters?.product_id,
      date_time: selectedFilters?.strike_date,
      risk_free: selectedFilters?.risk_free || 0,
    })
      .then((res) => {
        setStickyStrikeData(res.data.data);
        setIsCellEditing(false);
      })
      .catch((e) => {
        setIsCellEditing(false);
      });
  }

  const refreshTable = () => {
    let refreshParams = { force: true };
    gridRef.current.api.refreshCells(refreshParams);
  };
  return {
    onCellEditingStopped,
    setShowUpdateModal,
    showUpdateModal,
    revertCellValue,
    isCellEditing,
    onUpdatePremiums,
    premiums,
  };
}

export default useUpdateStrikeTable;

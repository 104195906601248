import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { getRowClass, getRowStyle } from "constants/agGridCommonStyle";
import { VOL_SURFACE_STICKY_STRIKE_COLS } from "constants/volSurface";
import { GlobalContext } from "contexts/GlobalContext";
import { useVolSurfaceControlTable } from "contexts/VolSurfaceControlTableContext";
import { cellClickedListener, getRowId } from "helpers/generalHelper";
import useUpdateStrikeTable from "./hooks/useUpdateStrikeTable";
import { onGridReady } from "helpers/volSurfaceHelper";
import { createPortal } from "react-dom";
import { Button, Modal } from "antd";

function SingleStrikeTable() {
  const [toNextCell, setToNextCell] = useState(false);
  const ref = useRef(null);
  const { setSelectedRows, stickyStrikeData, isCellLoading } =
    useVolSurfaceControlTable();

  const {
    globalState: { dark_Theme },
  } = useContext(GlobalContext);
  const gridRef = useRef();

  const {
    onCellEditingStopped,
    setShowUpdateModal,
    showUpdateModal,
    revertCellValue,
    isCellEditing,
    onUpdatePremiums,
    premiums,
  } = useUpdateStrikeTable({
    gridRef: gridRef,
    toNextCell: toNextCell,
  });

  const columnDefs = useMemo(
    () =>
      VOL_SURFACE_STICKY_STRIKE_COLS(
        isCellEditing && !toNextCell,
        isCellLoading
      ),
    [isCellEditing, toNextCell, isCellLoading]
  );

  const gridOptions = {
    stopEditingWhenGridLosesFocus: false,
    animateRows: true,
  };

  const refreshTable = () => {
    let refreshParams = { force: true };
    gridRef.current.api.refreshCells(refreshParams);
  };

  document.addEventListener(
    "focusin",
    (e) => {
      if (e.target.tagName === "INPUT") {
        e.target.setAttribute("autocomplete", "off");
      }
    },
    true
  );
  
  const onSelectionChanged = (e) => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    setSelectedRows(
      selectedRows.filter((item) => item.show_tenor_and_ul === false)
    );
    refreshTable();
  };

  const onCancel = () => {
    setShowUpdateModal({ data: null, show: false });
    revertCellValue(showUpdateModal.data);
  };

  const onCellKeyDown = (event) => {
    const { api, node, colDef } = event;
    event.event.preventDefault();
    if (colDef.field === "premium") {
      if (event.event.keyCode === 40) {
        api.stopEditing();
        const rowCount = event.api.getDisplayedRowCount();
        // index for the current row
        const rowIndex = event.node.rowIndex;
        if (rowIndex < rowCount - 1) {
          const nextRowNode = api.getDisplayedRowAtIndex(rowIndex + 1);
          const nextRowData = nextRowNode.data;
          const nextKey = nextRowData.vol_smile_id;
          if (event.data?.vol_smile_id === nextKey) {
            const nextRowIndex = node.rowIndex + 1;
            const colKey = colDef.field;
            api.ensureIndexVisible(nextRowIndex, "top");
            api.setFocusedCell(nextRowIndex, colKey);
            api.startEditingCell({
              rowIndex: nextRowIndex,
              colKey: colKey,
            });
          } else {
            api.stopEditing();
            const lastItem = {
              key: event.data?.key.toString(),
              value: event?.data?.premium,
              event: event,
            };
            onUpdatePremiums(event, lastItem);
          }
        }
      } else if (event.event.keyCode === 13) {
        api.stopEditing();
        const lastItem = {
          key: event.data?.key.toString(),
          value: event?.data?.premium,
          event: event,
        };
        onUpdatePremiums(event, lastItem);
      }
    } else {
      if (event.event.keyCode === 13) {
        setToNextCell(true);
      } else if (event.event.keyCode === 27) {
        setToNextCell(false);
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && premiums && !ref.current?.contains(event.target)) {
        if (premiums.length > 0) {
          onUpdatePremiums(null, null);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [premiums]);

  if (!stickyStrikeData?.table) return null;

  return (
    <div ref={ref} style={{ height: "100%", width: "100%" }}>
      <AgGridReact
        onCellKeyDown={onCellKeyDown}
        getRowId={getRowId}
        onCellEditingStopped={(event) => {
          if (
            event.colDef.field === "underlying" &&
            event.newValue !== null &&
            event.newValue !== +event.oldValue
          ) {
            setShowUpdateModal({
              show: true,
              data: event,
            });
          } else if (event.colDef.field === "underlying") {
            revertCellValue(event);
          } else {
            onCellEditingStopped(event);
          }
        }}
        gridOptions={gridOptions}
        onGridReady={onGridReady}
        headerHeight={30}
        defaultColDef={{ suppressMenu: true }}
        rowHeight={30}
        rowData={stickyStrikeData?.table}
        onSelectionChanged={onSelectionChanged}
        getRowStyle={(params) => {
          if (params.data.field !== "strike") {
            return {
              borderTop: "none",
              borderBottom: "none",
            };
          }
          getRowStyle(params);
        }}
        getRowClass={getRowClass}
        ref={gridRef}
        onCellValueChanged={(event) => {
          if (event.source === "paste") {
            onCellEditingStopped(event);
          }
        }}
        stopEditingWhenCellsLoseFocus={true}
        suppressMovableColumns={true}
        columnDefs={columnDefs}
        groupDefaultExpanded={1}
        suppressContextMenu
        checkboxSelection
        rowSelection={"multiple"}
        onCellClicked={(e) => {
          if (premiums.length > 0) {
            onUpdatePremiums(null, null);
          }
          if (e.colDef.field === "actions") return;
          cellClickedListener(e);
          refreshTable();
        }}
        suppressRowClickSelection
        className={`ag-theme-alpine pricer-ag-grid__table ${
          dark_Theme ? " ag-theme-balham-dark" : "ag-theme-balham"
        }`}
      />
      {createPortal(
        <Modal
          className={`${dark_Theme && "ant-modal-dark content_dark"}`}
          title="Would you like to keep or reset the strikes?"
          style={{
            top: 20,
          }}
          confirmLoading={false}
          open={showUpdateModal.show}
          footer={[
            <Button type="ghost" onClick={onCancel}>
              Cancel
            </Button>,
          ]}
          onCancel={onCancel}
          centered
        >
          <div className="d-flex flex-column align-items-center">
            <div className="d-flex justify-content-center gap-3 w-50 align-items-center">
              <Button
                className="w-50"
                type="primary"
                onClick={() => {
                  setShowUpdateModal({ show: false });
                  onCellEditingStopped(showUpdateModal.data, 1);
                }}
              >
                Keep
              </Button>
              <Button
                className="w-50"
                type="default"
                onClick={() => {
                  setShowUpdateModal({ show: false });
                  onCellEditingStopped(showUpdateModal.data, 0);
                }}
              >
                Reset
              </Button>
            </div>
          </div>
        </Modal>,
        document.body
      )}
    </div>
  );
}

export default SingleStrikeTable;

import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  useCallback,
} from "react";
import { Col, Row, Modal, InputNumber, Select } from "antd";
import { debounce } from "lodash";

//Helpers
import {
  EDIT_TRADE_DETAILS_LABELS,
  STATE_KEYS_NAME,
} from "constants/auditTrail";

import {
  handleCopyTradeDetails,
  setDataToLocalStorage,
  toast,
} from "helpers/generalHelper";
//Components
// Context
import { AuditTrailContext } from "contexts/AuditTrailContext";
// Style
import "./EditTradeDetails.scss";
import { GlobalContext } from "contexts/GlobalContext";
import {
  getEditEditTRadeDetails,
  storeOrUpdateTradeDetails,
  updatePremiumTrade,
  updateTradeLegs,
} from "api/editTradeDetailsApi";
import useDebounce from "hooks/useDebounce";
import EditTradePremium from "./EditTradePremium";
import BuyOrSellButtons from "./BuyOrSellButtons";
import { EditTradeFooter } from "./EditTradeFooter";
import { API_KEYS, BACKEND_KEYS_NAME } from "constants/editTradeDetails";
import PreLoadPagesSpin from "components/PreLoadPagesSpin";
import { returnSelectedIdForEditTradeDetails } from "helpers/editTradeDetailsHelper";
import AddTraderForm from "components/TeamRun/AddTraderForm";
import { useLocation } from "react-router-dom";
import { ROUTES_PATH } from "routes";
import TradeColItem from "./TradeColItem";

const EditTradeDetails = ({ isEditMode = false, ...props }) => {
  const location = useLocation();
  const { setOnSaveAndCreateClicked } = useContext(AuditTrailContext);
  const {
    globalState: { dark_Theme },
  } = useContext(GlobalContext);

  //#States
  const [isSellActive, setIsSellActive] = useState(null);
  const [isReverse, setIsReverse] = useState(true);
  const [isTradeRequestFire, setIsTradeRequestFire] = useState(null);
  const [tradeData, setTradeData] = useState();
  const [oldTradeData, setOldTradeData] = useState({
    leg_premium: 0,
    traded_premium: 0,
    quantity_t: 0,
    delta_quantity_t: 0,
    delta: 0,
    leg_quantity_t: {},
  });
  const [isAddTraderOpen, setIsAddTraderOpen] = useState(false);
  // const [isAddCompanyOpen, setIsAddCompanyOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [exChangeData, setExChangeData] = useState([]);
  const [tradingCompanyId, setTradingCompanyId] = useState();
  const [isCreateLoading, setIsCreateLoading] = useState(false);
  const [isRequestActive, setIsRequestActive] = useState(false);
  const [isPremiumRequestActive, setIsPremiumRequestActive] = useState(false);
  const [isQuantityRequestActive, setIsQuantityRequestActive] = useState(false);
  const [isDeltaRequestActive, setIsDeltaRequestActive] = useState(false);
  const [isLegPremiumRequestActive, setIsLegPremiumRequestActive] =
    useState(false);

  const [editTradeValue, setEditTradeValue] = useState(undefined);
  const [editQuantityValue, setEditQuantityValue] = useState(undefined);
  const debouncedTradePremiumValue = useDebounce(editTradeValue, 1000);
  const [currentCol, setCurrentCol] = useState(null);

  // use effect on isSellActive change
  useEffect(() => {
    setEditQuantityValue(undefined);
  }, [isSellActive]);

  useEffect(() => {
    if (debouncedTradePremiumValue != null) {
      handleTradePremium();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedTradePremiumValue]);

  useEffect(() => {
    if (isTradeRequestFire && tradeData?.data?.sell_or_buy === null) {
      getTradeDetailsData();
    } else if (isSellActive === null) {
      getTradeDetailsData(true);
    } else {
      getTradeDetailsData(false, false, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTradeRequestFire, isSellActive]);

  useEffect(() => {
    if (tradeData?.data?.trades) {
      tradeData.data.trades?.forEach((item, index) => {
        item.hedges.forEach((hedge, hedgeIndex) => {
          hedge.col_index = index;
          hedge.hedge_col = hedgeIndex;
        });
        item.legs.at(0).leg_col = index;
      });
    }
  }, [tradeData?.data?.trades]);

  function getTradeDetailsData(
    isFirst = false,
    withLoading = true,
    resetData = true
  ) {
    let bodyParams = {
      team_run_option_id: props.rowSelectedInfo?.["team_run_option_id"],
    };
    if (isFirst) {
      bodyParams["summary_id"] = props.rowSelectedInfo?.["summary_id"];
      bodyParams["is_sell"] = 0;
      bodyParams["show_null_values"] = 1;
    } else {
      bodyParams["temp_summary_id"] = tradeData?.data?.["temp_summary_id"];
      bodyParams["is_sell"] = isSellActive ? 1 : 0;
    }
    setIsRequestActive(true);
    if (withLoading) {
      setIsLoading(true);
      setTradeData({});
    }
    getEditEditTRadeDetails(bodyParams)
      .then((res) => {
        // setIsReverse(true);
        setDataToLocalStorage(
          "initial_hedges_sell_or_buy",
          res?.data?.data?.left.hedges.map((hed) => {
            return {
              id: hed.id,
              sell_or_buy: hed.sell_or_buy,
            };
          })
        );
        if (resetData) {
          setTradeData(res?.data);
          setIsLoading(false);
          setExChangeData(
            res.data?.data?.left?.["exchanges"].map((item) => ({
              ...item,
              label: item[BACKEND_KEYS_NAME.NAME],
              value: item[BACKEND_KEYS_NAME.ID],
            }))
          );
        } else {
          setTradeData((prev) => {
            return {
              ...prev,
              data: {
                ...prev.data,
                left: {
                  ...prev.data.left,
                  is_sell: res?.data?.data?.left?.is_sell,
                  legs: prev.data.left?.legs.map((item) => {
                    return {
                      ...item,
                      sell_or_buy: res?.data?.data?.left?.legs?.find(
                        (leg) => leg.id === item.id
                      )?.sell_or_buy,
                    };
                  }),
                  hedges: prev.data.left?.hedges.map((item) => {
                    const hedgeSellOrBuy = res?.data?.data?.left?.hedges?.find(
                      (hedge) => hedge.id === item.id
                    )?.sell_or_buy;
                    return {
                      ...item,
                      sell_or_buy:
                        item.sell_or_buy !== hedgeSellOrBuy
                          ? hedgeSellOrBuy
                          : item.sell_or_buy === "buy"
                          ? "sell"
                          : "buy",
                    };
                  }),
                },
                trades: prev.data.trades.map((item) => {
                  return {
                    ...item,
                    legs: item.legs,
                    hedges: item.hedges.map((hed) => {
                      const hedgeSellOrBuy =
                        res?.data?.data?.left?.hedges?.find(
                          (hedge) => hedge.id === hed.id
                        )?.sell_or_buy;
                      return {
                        ...hed,
                        sell_or_buy:
                          hed.sell_or_buy !== hedgeSellOrBuy
                            ? hedgeSellOrBuy
                            : hed.sell_or_buy === "buy"
                            ? "sell"
                            : "buy",
                      };
                    }),
                  };
                }),
              },
            };
          });
        }
      })
      .catch((error) => {
        setIsTradeRequestFire(null);
        console.log("error > ", error);
      })
      .finally(() => {
        setIsLoading(false);
        setIsRequestActive(false);
      });
  }

  const handleLegsPremiumChange = (value, id, updateValue = true) => {
    setTradeData((prev) => {
      return {
        ...prev,
        data: {
          ...prev.data,
          left: {
            exchange_placeholder: prev.data.left?.exchange_placeholder,
            exchanges: prev.data.left?.exchanges,
            hedges: prev.data.left?.hedges,
            is_sell: prev.data.left?.is_sell,
            title: prev.data.left?.title,
            legs: prev.data.left.legs.map((item) => {
              if (item.id !== id) return item;
              if (updateValue) {
                setOldTradeData((old) => ({
                  ...old,
                  leg_premium: item.premium.value,
                }));
              }
              item.premium = {
                ...item.premium,
                value,
              };
              return item;
            }),
          },
        },
      };
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedLegPremeiumChange = useCallback(
    debounce((value, id) => {
      const tradesData = [];
      tradeData?.data?.trades?.forEach((item) => {
        tradesData.push({
          column_id: item.column_id ?? item.id,
          quantity_t: item.quantity_t.value,
        });
      });
      const prepareBody = {
        [BACKEND_KEYS_NAME.IS_SELL]: isSellActive ? 1 : 0,
        [BACKEND_KEYS_NAME.LEGS]: [
          {
            [BACKEND_KEYS_NAME.PREMIUM]: value,
            [BACKEND_KEYS_NAME.ID]: id,
          },
        ],
        ...((props.isTeamRun || props.isPricer) && {
          [BACKEND_KEYS_NAME.TEM_SUMMARY_ID]: tradeData?.data?.temp_summary_id,
        }),
        trades: tradesData,
      };
      setIsRequestActive(true);
      setIsLegPremiumRequestActive(true);
      updateTradeLegs(prepareBody)
        .then((res) => {
          setIsReverse(true);
          const data = res?.data.data;
          setTradeData((prev) => {
            return {
              ...prev,
              data: {
                ...prev.data,
                traded_premium: data?.traded_premium,
                // trades: data?.trades ?? prev.data?.trades,
                trades: prev.data.trades.map((item) => {
                  return {
                    ...item,
                    quantity_t: {
                      ...item.quantity_t,
                      value: data?.trades?.find(
                        (trade) => trade.column_id === item.column_id
                      )?.quantity_t.value,
                    },
                    // hedges: item.hedges.map((hedge) => {
                    //   return {
                    //     ...hedge,
                    //     delta_quantity_t: {
                    //       ...hedge.delta_quantity_t,
                    //       value: data?.trades
                    //         ?.find(
                    //           (trade) => trade.column_id === item.column_id
                    //         )
                    //         ?.hedges?.find((el) => el.id === hedge.id)
                    //         ?.delta_quantity_t?.value,
                    //     },
                    //     delta: {
                    //       ...hedge.delta,
                    //       value: data?.trades
                    //         ?.find(
                    //           (trade) => trade.column_id === item.column_id
                    //         )
                    //         ?.hedges?.find((el) => el.id === hedge.id)?.delta
                    //         ?.value,
                    //     },
                    //   };
                    // }),
                    hedges: data?.trades?.find(
                      (trade) => trade.column_id === item.column_id
                    )?.hedges,
                    legs: item.legs.map((leg) => {
                      return {
                        ...leg,
                        quantity_t: {
                          ...leg.quantity_t,
                          value: data?.trades
                            ?.find(
                              (trade) => trade.column_id === item.column_id
                            )
                            ?.legs?.find((el) => el.id === leg.id)?.quantity_t
                            ?.value,
                        },
                      };
                    }),
                  };
                }),
                left: {
                  exchange_placeholder: prev.data.left?.exchange_placeholder,
                  exchanges: prev.data.left?.exchanges,
                  is_sell: data?.is_sell,
                  title: data?.title,
                  //update hedges except the sell_or_buy
                  hedges: data?.hedges,
                  legs: data?.legs,
                },
              },
            };
          });
        })
        .catch((error) => {
          handleLegsPremiumChange(oldTradeData.leg_premium, id, false);
          console.log("error> ", error);
        })
        .finally(() => {
          setIsRequestActive(false);
          setIsLegPremiumRequestActive(false);
        });
    }, 1000),
    [tradeData?.data?.temp_summary_id, tradeData?.data?.trades]
  );

  function handleTradePremium() {
    // send array of trades
    const tradesData = [];
    tradeData?.data?.trades?.forEach((item) => {
      tradesData.push({
        column_id: item.column_id ?? item.id,
        quantity_t: item.quantity_t.value,
      });
    });
    const prepareBody = {
      is_sell: isSellActive ? 1 : 0,
      [BACKEND_KEYS_NAME.TEM_SUMMARY_ID]: tradeData.data?.temp_summary_id,
      premium: debouncedTradePremiumValue,
      ...((props.isTeamRun || props.isPricer) && {
        summary_id: +returnSelectedIdForEditTradeDetails(props).split("/")[1],
      }),
      trades: tradesData,
    };

    setOldTradeData((old) => ({
      ...old,
      traded_premium: tradeData?.data?.traded_premium.value,
    }));

    setIsRequestActive(true);
    setIsPremiumRequestActive(true);
    updatePremiumTrade(prepareBody)
      .then((res) => {
        setIsReverse(true);
        const data = res.data.data;
        setEditTradeValue(undefined);
        setTradeData((prev) => {
          return {
            ...prev,
            data: {
              ...prev.data,
              traded_premium: data?.traded_premium,
              // trades: data?.trades ?? prev.data?.trades,
              trades: prev.data.trades.map((item) => {
                return {
                  ...item,
                  quantity_t: {
                    ...item.quantity_t,
                    value: data?.trades?.find(
                      (trade) => trade.column_id === item.column_id
                    )?.quantity_t.value,
                  },
                  hedges: data?.trades?.find(
                    (trade) => trade.column_id === item.column_id
                  )?.hedges,

                  // hedges: item.hedges.map((hedge) => {
                  //   return {
                  //     ...hedge,
                  //     delta_quantity_t: {
                  //       ...hedge.delta_quantity_t,
                  //       value: data?.trades
                  //         ?.find((trade) => trade.column_id === item.column_id)
                  //         ?.hedges?.find((el) => el.id === hedge.id)
                  //         ?.delta_quantity_t?.value,
                  //     },
                  //     delta: {
                  //       ...hedge.delta,
                  //       value: data?.trades
                  //         ?.find((trade) => trade.column_id === item.column_id)
                  //         ?.hedges?.find((el) => el.id === hedge.id)?.delta
                  //         ?.value,
                  //     },
                  //   };
                  // }),
                  legs: item.legs.map((leg) => {
                    return {
                      ...leg,
                      quantity_t: {
                        ...leg.quantity_t,
                        value: data?.trades
                          ?.find((trade) => trade.column_id === item.column_id)
                          ?.legs?.find((el) => el.id === leg.id)?.quantity_t
                          ?.value,
                      },
                    };
                  }),
                };
              }),
              left: {
                exchange_placeholder: prev.data.left?.exchange_placeholder,
                exchanges: prev.data.left?.exchanges,
                is_sell: data?.is_sell,
                title: data?.title,
                hedges: data?.hedges,
                legs: data?.legs,
              },
            },
          };
        });
      })
      .catch((error) => {
        setEditTradeValue(oldTradeData.traded_premium);
        console.log("error> ", error);
      })
      .finally(() => {
        setIsRequestActive(false);
        setIsPremiumRequestActive(false);
      });
  }

  const triggerPostEditApi = () => {
    setIsCreateLoading(true);
    const prepareBody = {
      team_run_option_id:
        props.rowSelectedInfo?.[
          location.pathname.includes(ROUTES_PATH.AUDIT_TRAIL.index)
            ? BACKEND_KEYS_NAME.TEAM_RUN_OPTION_ID
            : BACKEND_KEYS_NAME.ID
        ],
      temp_summary_id: tradeData.data.temp_summary_id,
      exchange_id: tradeData.data.exchange_id,
      is_sell: isSellActive ? 1 : 0,
      traded_premium: tradeData.data.traded_premium.premium.value,
    };
    const trades = tradeData.data.trades.map((item) => {
      return {
        trading_company_id:
          tradeData.data.trades?.at(0)?.["trading_company_id"],
        trader_id: item["trader_id"],
        quantity: item.quantity_t?.value,
        hedge_rows: item.hedges.map((hedge) => {
          return {
            id: hedge.id,
            sell_or_buy: tradeData?.data?.left?.hedges?.find(
              (el) => el.id === hedge.id
            )?.sell_or_buy,
            delta_quantity_t: hedge?.delta_quantity_t?.value,
            delta_t: hedge.delta?.value,
            underlying: hedge?.underlying?.value,
            underlying_tenor: hedge?.underlying_tenor,
          };
        }),
      };
    });

    prepareBody["trades"] = trades;
    setIsRequestActive(true);
    storeOrUpdateTradeDetails(props, prepareBody)
      .then((res) => {
        handleCopyTradeDetails(res.data.data["copy_trade_details"]);
        !props.isTeamRun && !props.isPricer && setOnSaveAndCreateClicked(true);
        toast(res.data?.message, "success");
        setIsCreateLoading(false);
      })
      .catch((err) => {
        setIsCreateLoading(false);
      })
      .finally(() => {
        setIsRequestActive(false);
      });
    setDataToLocalStorage("initialTrade", {});
  };
  //Checking for validation
  const isSaveAndCreateButtonDisabled = useMemo(() => {
    const isNotEmpty = tradeData?.data?.trades?.every((item, index) => {
      return index === 0
        ? item.trading_company !== null && item.trader !== null
        : item.trader !== null;
    });
    return !(tradeData?.data?.exchange_id && isNotEmpty);
  }, [tradeData?.data?.exchange_id, tradeData?.data?.trades]);

  const handleExchangeChange = (value) => {
    setTradeData((prev) => {
      return {
        ...prev,
        data: {
          ...prev.data,
          exchange_id: value,
        },
      };
    });
  };

  //Handle the Trader change input
  const handleTraderChange = (id, value, trader_id) => {
    const trades = tradeData.data.trades.map((item) => {
      if (item.column_id === id) {
        item.trader = value;
        item["trader_id"] = trader_id;
      }
      return item;
    });
    setTradeData((prev) => {
      return {
        ...prev,
        data: {
          ...prev.data,
          trades: trades,
        },
      };
    });
    setDataToLocalStorage("initialTrade", {
      trader_id: tradeData.data.trades[0].trader_id,
      trader: tradeData.data.trades[0].trader,
    });
  };

  const handleLegsQuantityChange = (id, value) => {
    const leftLegs = tradeData.data.left?.legs?.map((item) => {
      item.volatility.value = value;
      return item;
    });
    setTradeData((prev) => {
      return {
        ...prev,
        data: {
          ...prev.data,
          left: {
            exchange_placeholder: prev.data.left?.exchange_placeholder,
            exchanges: prev.data.left?.exchanges,
            hedges: prev.data.left?.hedges,
            is_sell: prev.data.left?.is_sell,
            title: prev.data.left?.title,
            legs: leftLegs,
          },
        },
      };
    });
  };

  function onAddTrader() {
    setIsAddTraderOpen(true);
  }
  // function onAddCompany() {
  //   setIsAddCompanyOpen(true);
  // }

  const isRequestsActive = useMemo(() => {
    return (
      isLegPremiumRequestActive ||
      isPremiumRequestActive ||
      isQuantityRequestActive ||
      isDeltaRequestActive ||
      isCreateLoading
    );
  }, [
    isLegPremiumRequestActive,
    isPremiumRequestActive,
    isQuantityRequestActive,
    isDeltaRequestActive,
    isCreateLoading,
  ]);

  return (
    <Modal
      title={EDIT_TRADE_DETAILS_LABELS.TRADE_OPTION}
      className={dark_Theme && "content_dark ant-modal-dark"}
      open={props.isOpen}
      bodyStyle={{ overflowX: "auto", margin: 16 }}
      footer={EditTradeFooter(
        props,
        triggerPostEditApi,
        isRequestActive,
        isCreateLoading,
        isSaveAndCreateButtonDisabled,
        onAddTrader
        // onAddCompany
      )}
      width={"80vw"}
      onCancel={() => {
        setDataToLocalStorage("initialTrade", {});
        props.handleMenuItemClicked(false);
      }}
    >
      <div className="edit-trade-details">
        {/* Show Sell And Buy Button */}
        <Row gutter={[0, 15]}>
          <BuyOrSellButtons
            isLoading={isLoading || isRequestsActive}
            isSellActive={isSellActive}
            setIsSellActive={setIsSellActive}
            setIsTradeRequestFire={setIsTradeRequestFire}
          />
          {isLoading ? (
            <PreLoadPagesSpin
              className={" edit-trade-loading"}
              dark_Theme={dark_Theme}
            />
          ) : (
            <>
              <EditTradePremium
                isTradeRequestFire={isTradeRequestFire}
                tradedPremium={tradeData?.data?.traded_premium}
                disabled={isRequestsActive}
                editTradeValue={editTradeValue}
                setEditTradeValue={setEditTradeValue}
                setIsRequestActive={setIsRequestActive}
              />
              {/* Show Traded premium */}
              <Col span={24}>
                <strong className="title">
                  {tradeData?.data?.left?.title || "API2 Feb-23:Put 0.000"}
                </strong>
              </Col>
              {/* End Of Show Sell And Buy */}
              <div className="d-flex  mt-5">
                <div
                  className="left-trade"
                  style={{
                    marginTop: "5px",
                    width: "650px",
                  }}
                >
                  {/* for alignment */}
                  <Col className="opacity-0" span={18}>
                    .
                  </Col>
                  <Col className="opacity-0" span={18}>
                    .
                  </Col>
                  <Col className="opacity-0" span={18}>
                    .
                  </Col>
                  <Col className="opacity-0" span={18}>
                    .
                  </Col>
                  {tradeData?.data?.left?.legs?.map((leg, index) => {
                    return (
                      <div className="d-flex flex-column gap-3">
                        <Row gutter={20}>
                          <Col span={18}></Col>
                          <Col span={2}>
                            {index === 0 && (
                              <span>
                                {leg["sell_or_buy"] && (
                                  <span className="sell-or-buy--label">
                                    {leg["sell_or_buy"]}
                                  </span>
                                )}
                              </span>
                            )}
                          </Col>
                          <Col span={3}>
                            {index === 0 && (
                              <Select
                                disabled={
                                  isTradeRequestFire === null ||
                                  isRequestsActive
                                }
                                placeholder={
                                  tradeData?.data?.left?.[
                                    API_KEYS.EXCHANGE_PLACEHOLDER
                                  ]
                                }
                                key={leg[STATE_KEYS_NAME.EXCHANGE]}
                                popupClassName={dark_Theme && "dropdown-dark"}
                                className="audit-drop-down"
                                defaultValue={tradeData.data.exchange_id}
                                onChange={(value) =>
                                  handleExchangeChange(value)
                                }
                                options={exChangeData}
                                value={tradeData.data.exchange_id}
                                style={{ minWidth: 95, maxWidth: 95 }}
                              />
                            )}
                          </Col>
                        </Row>
                        <Col span={24}>
                          <Row gutter={20}>
                            <Col span={9}>
                              <span className="me-5">{leg.label}</span>
                            </Col>
                            <Col span={5}>
                              <InputNumber
                                onStep={() => setIsRequestActive(true)}
                                onInput={() => setIsRequestActive(true)}
                                onFocus={() => setIsRequestActive(true)}
                                onBlur={() => setIsRequestActive(false)}
                                placeholder={
                                  leg?.[API_KEYS.QUANTIZE_PLACEHOLDER]
                                }
                                disabled={
                                  isTradeRequestFire === null ||
                                  isRequestsActive ||
                                  leg?.[BACKEND_KEYS_NAME.VOLATILITY]?.[
                                    BACKEND_KEYS_NAME.IS_DISABLED
                                  ] ||
                                  true
                                }
                                value={
                                  leg?.[BACKEND_KEYS_NAME.VOLATILITY]?.value
                                }
                                onChange={(value) => {
                                  handleLegsQuantityChange(leg.id, value);
                                }}
                                addonAfter={
                                  leg?.[BACKEND_KEYS_NAME.VOLATILITY]?.symbol
                                }
                              />
                            </Col>
                            <Col span={4}>
                              <InputNumber
                                onStep={() => setIsRequestActive(true)}
                                onInput={() => setIsRequestActive(true)}
                                onFocus={() => setIsRequestActive(true)}
                                onBlur={() => setIsRequestActive(false)}
                                type="number"
                                placeholder={
                                  leg?.[API_KEYS.QUANTIZE_PLACEHOLDER]
                                }
                                disabled={
                                  isTradeRequestFire === null ||
                                  isRequestsActive ||
                                  leg?.[BACKEND_KEYS_NAME.PREMIUM]
                                    ?.is_disabled ||
                                  false
                                }
                                defaultValue={
                                  leg?.[BACKEND_KEYS_NAME.PREMIUM]?.[
                                    BACKEND_KEYS_NAME.VALUE
                                  ]
                                }
                                value={
                                  leg?.[BACKEND_KEYS_NAME.PREMIUM]?.[
                                    BACKEND_KEYS_NAME.VALUE
                                  ]
                                }
                                step={
                                  leg?.[BACKEND_KEYS_NAME.STEP_SIZE] || 0.005
                                }
                                onChange={(value) => {
                                  handleLegsPremiumChange(value, leg.id);
                                  debouncedLegPremeiumChange(value, leg.id);
                                }}
                              />
                            </Col>
                            <Col span={2}>
                              <span>
                                {leg["sell_or_buy"] && (
                                  <span className="sell-or-buy--label">
                                    {leg["sell_or_buy"]}
                                  </span>
                                )}
                              </span>
                            </Col>
                          </Row>
                        </Col>
                      </div>
                    );
                  })}
                  <Col span={24} style={{ marginTop: "20px" }}>
                    {tradeData?.data?.left?.hedges.map((hedge, hedgeIndex) => (
                      <Row gutter={20}>
                        {hedgeIndex !== 0 && (
                          <>
                            <Col className="opacity-0" span={18}>
                              y
                            </Col>
                            <Col className="opacity-0" span={18}>
                              y
                            </Col>
                          </>
                        )}
                        <Col span={9}>
                          <span>{hedge?.label}</span>
                        </Col>
                        <Col span={5}></Col>
                        <Col span={4}>
                          <p
                            style={{ textAlign: "start" }}
                            className="edit-trade-underlying"
                          >
                            {
                              hedge[BACKEND_KEYS_NAME.UNDERLYING]?.[
                                BACKEND_KEYS_NAME.VALUE
                              ]
                            }
                          </p>
                        </Col>
                        <Col span={3}>
                          {hedge["sell_or_buy"] && (
                            <span className="sell-or-buy--label">
                              {hedge["sell_or_buy"]}
                            </span>
                          )}
                        </Col>
                      </Row>
                    ))}
                  </Col>
                </div>
                <div className="right-trade d-flex  mx-5">
                  {tradeData?.data?.trades?.map((col, colIndex) => {
                    return (
                      <TradeColItem
                        key={col.column_id ?? colIndex}
                        col={col}
                        isReverse={isReverse}
                        setIsReverse={setIsReverse}
                        props={props}
                        tradeData={tradeData}
                        colIndex={colIndex}
                        tradingCompanyId={tradingCompanyId}
                        setTradingCompanyId={setTradingCompanyId}
                        handleTraderChange={handleTraderChange}
                        editQuantityValue={editQuantityValue}
                        isTradeRequestFire={isTradeRequestFire}
                        setIsRequestActive={setIsRequestActive}
                        handleLegsQuantityChange={handleLegsQuantityChange}
                        setTradeData={setTradeData}
                        isSellActive={isSellActive}
                        isRequestActive={isRequestActive}
                        currentCol={currentCol}
                        isRequestsActive={isRequestsActive}
                        setIsQuantityRequestActive={setIsQuantityRequestActive}
                        setIsDeltaRequestActive={setIsDeltaRequestActive}
                        setCurrentCol={setCurrentCol}
                        oldTradeData={oldTradeData}
                        setOldTradeData={setOldTradeData}
                      />
                    );
                  })}
                </div>
              </div>
            </>
          )}
        </Row>
      </div>
      {isAddTraderOpen && (
        <AddTraderForm
          setTradingCompanyId={setTradingCompanyId}
          isOpen={isAddTraderOpen}
          setIsOpen={setIsAddTraderOpen}
          TradingCompanyId={tradingCompanyId}
          setTraderId={() => {}}
          onTraderChange={handleTraderChange}
        />
      )}
      {/* {isAddCompanyOpen && (
        <AddCompanyForm
          isOpen={isAddCompanyOpen}
          setIsOpen={setIsAddCompanyOpen}
        />
      )} */}
    </Modal>
  );
};
export default EditTradeDetails;

import { InputNumber } from "antd";
import { API_KEYS, BACKEND_KEYS_NAME } from "constants/editTradeDetails";

function LegQuantityItem({
  leg,
  handleLegsQuantityChange,
  isTradeRequestFire,
  setIsRequestActive
}) {

  
  return (
    <div className="my-3" style={{ width: 200 }}>
      <InputNumber
        placeholder={leg?.[API_KEYS.QUANTIZE_PLACEHOLDER]}
        disabled={
          isTradeRequestFire === null ||
          leg[BACKEND_KEYS_NAME.QUANTITY_T]?.[BACKEND_KEYS_NAME.IS_DISABLED] ||
          false
        }
        onStep={() => setIsRequestActive(true)}
        onInput={() => setIsRequestActive(true)}
        onFocus={() => setIsRequestActive(true)}
        onBlur={() => setIsRequestActive(false)}
        value={leg[BACKEND_KEYS_NAME.QUANTITY_T]?.value}
        step={
          leg[BACKEND_KEYS_NAME.QUANTITY_T]?.[BACKEND_KEYS_NAME.STEP_SIZE] || 1
        }
        onChange={(value) => {
          handleLegsQuantityChange(leg.id, value);
        }}
        addonAfter={leg[BACKEND_KEYS_NAME.QUANTITY_T]?.[BACKEND_KEYS_NAME.SYMBOL]}
      />
    </div>
  );
}

export default LegQuantityItem
